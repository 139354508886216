import axios from "axios";
import { USER_DATA } from "../utils/CONSTANTS";
import { authStorage } from "../utils/login";
import { baseURL } from "../utils/urls";
import { Services } from "./service";

//****************Login************************

export const UserLogin = async (payload) => {
  let data = await Services.post("/auth/login", payload);
  return data;
};

export const SendCoupon = async (payload) => {
  let data = await Services.post("/coupons/reminder", payload);
  return data;
};

export const AddUser = async (payload) => {
  let data = await Services.post("/merchants", payload);
  return data;
};

//****************Merchant Delete********************

export const DeleteMarchant = async (params) => {
  let data = await Services.delete(
    `/merchants/temp/delete/merchant/${params}`
  );
  return data;
};

//****************Dashboard********************
export const GetDashboardData = async (startDate, endDate) => {
  let tempUrlgetTransactionAnalytics = "/transctions/analytics";
  let tempgetAllReturnCustomer = "/transctions/return-cust";

  if (authStorage.role !== "merchant") {
    tempUrlgetTransactionAnalytics += `/${authStorage.mid}/?startDate=`;
    tempgetAllReturnCustomer += `/${authStorage.mid}/?startDate=`;
  } else {
    tempUrlgetTransactionAnalytics += `/${USER_DATA.user.mid}/?startDate=`;
    tempgetAllReturnCustomer += `/${USER_DATA.user.mid}/?startDate=`;
  }

  const getAllTransaction = Services.post(
    baseURL +
    "/transctions/get?startDate=" +
    startDate +
    "&endDate=" +
    endDate +
    "&sortBy[transaction_total]=DESC&sortBy[discount_total]=DESC"
  );
  const getAllMerchants = Services.post(
    baseURL +
    "/transctions/get?startDate=" +
    startDate +
    "&endDate=" +
    endDate +
    "&sortBy[transaction_total]=DESC&sortBy[discount_total]=DESC"
  );

  return await axios
    .all([getAllTransaction, getAllMerchants])
    .then(
      axios.spread((...responses) => {
        return responses;
      })
    )
    .catch((errors) => {
      return -1;
    });
};
