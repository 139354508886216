import { useEffect, useState } from "react";
import PageHeader from "../../../components/page-header";
import Table from "../../../components/Ui/table";
import useAPI from "../../../CustomeHooks/API";
import { API_ROUTE, CONSTANTS } from "../../../utils/CONSTANTS";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { DeleteMarchant } from "../../../api/allServices";
import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import { NavConstant } from "../../../store/context";

const AllMerchants = () => {
  const Context = useContext(NavConstant);
  const headers = [
    "no",
    "MID",
    "Name",
    "Email",
    "Mobile Number",
    "Address",
    "City",
    "State",
    "Zip",
    "Hear About Us",
    "Agent Or Msp Name",
    "Discount Percentage",
    "Discount Amount",
    "Opt In Signage",

    "Opt In Double Discount",
    "Opt In Mobile Coupon",

    "Legal Business Name",
    "Signed By",
    "Coupon Expires In",
    "Role",
    "Action",
  ];

  let merchantsArr = [];

  const merchantAPI = useAPI(API_ROUTE.getAllMerchants);
  const [searchWord, setSearchWord] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    merchantAPI.apiCall();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (merchantAPI.res !== -2 && merchantAPI.res !== -1) {
    let resData = merchantAPI.res.data.data.data;
    resData.filter((row, index) => {
      if (row.email.includes(searchWord)) {
        return merchantsArr.push({
          no: index + 1,
          mid: row.mid,
          name: row.firstname + " " + row.lastname,
          email: row.email,
          mobile_number: row.mobile_number,
          address: row.address,
          city: row.city,
          state: row.state,
          zip: row.zip,
          hear_about_us: row.hear_about_us,
          agent_or_msp_name: row.agent_or_msp_name,
          discount_percentage: row.discount_percentage,
          discount_amount: row.discount_amount,
          opt_in_signage: row.opt_in_signage,
          opt_in_double_discount: row.opt_in_double_discount ? "Yes" : "No",
          opt_in_mobile_coupon: row.opt_in_mobile_coupon ? "Yes" : "No",
          legal_business_name: row.legal_business_name,
          signed_by: row.signed_by,
          coupon_expires_in: row.coupon_expires_in,
          role: row.role,
          Action: (
            <DeleteIcon
              onClick={() => onDeleteMarchant(row.email)}
              style={{ cursor: "pointer" }}
            />
          ),
        });
      } else return;
    });
  }

  const handleSearch = (e) => {
    setSearchWord(e.target.value);
  };

  const onDeleteMarchant = (email) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Delete This Merchant",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await DeleteMarchant(email);
        if (res.status) {
          Context.setIsErrorNotification({
            type: true,
            message: "Delete Successfully",
          });
        } else {
          Context.setIsErrorNotification({
            type: true,
            message: "Please try again",
          });
        }
        merchantAPI.apiCall();
      }
    });
  };

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader
        destination="All Merchants"
        heading={CONSTANTS.ALL_BUSSINESS_HEADING}
      />
      <div className="container-fluid py-4 overflow-scroll">
        <h3>{CONSTANTS.ALL_MERCHANTS_HEADING}</h3>
        <div className="col-sm-12 col-md-6 mt-4">
          <div id="mytable_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="mytable"
                onChange={handleSearch}
              />
            </label>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center mt-5 pt-5">
            <CircularProgress />
          </div>
        ) : (
          merchantsArr.length > 0 && (
            <div className="col-12 overflow-scroll">
              <Table headers={headers} data={merchantsArr} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AllMerchants;
