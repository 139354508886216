import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CONSTANTS, USER_DATA } from "../../utils/CONSTANTS";
import "./LeftNavigationBar.css";
import NavbarItem from "./navbar-item";
import logo from "../../assets/img/logo.png";
import { NavConstant } from "../../store/context";
import NavbarSubListItem from "./navbar-sub-list-Item";

export default function LeftNavigationBar() {
  const navContext = useContext(NavConstant);
  const [navMenu, setNavMenu] = useState(CONSTANTS.NAVIGATION_PENAL_MENU);

  useEffect(() => {
    setNavMenu(
      CONSTANTS.NAVIGATION_PENAL_MENU.filter((navItem) =>
        USER_DATA.user?.role ? navItem.role[USER_DATA?.user?.role] : true
      )
    );
  }, [USER_DATA.user]);
  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer position-absolute end-0 top-0 "
          aria-hidden="true"
          id="iconSidenav"
          style={{ display: navContext.isNavigationOverLap ? "block" : "none" }}
          onClick={() => navContext.setIsNavigationOverLap(true)}
        ></i>
        <Link className="navbar-brand m-0" to="/all_merchants">
          <img src={logo} className="" alt="main_logo" />
        </Link>
      </div>

      <hr className="horizontal dark mt-0" />

      <div
        className="collapse navbar-collapse w-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          {navMenu.map((navItem) => (
            <NavbarItem key={"menu" + navItem.id} menuItem={navItem} />
          ))}
        </ul>
      </div>

      <Link
        to={"/terms"}
        className="nav-link"
        onClick={() => navContext.setIsNavigationOverLap(true)}
        style={{
          color: "#fff",
          textAlign: "center",
          padding: "0px",
          position: "absolute",
          margin: "0px",
          bottom: "20px",
          marginLeft: "29px",
          fontSize: "16px",
        }}
      >
        <span className="nav-link-text ms-1">Terms of Use</span>
      </Link>
    </aside>
  );
}
