import { LinearProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetDashboardData } from "../../api/allServices";
import CustomDate from "../../components/Home/customDate/customDate";
import PageHeader from "../../components/page-header";
import { CONSTANTS, USER_DATA } from "../../utils/CONSTANTS";
import "./DashboardRoutes/dashboardRoutes.css";
import { formatNumber } from "../../utils/storage";
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
export default function Home() {
  const [heading, setHeading] = useState("");
  const [destination, setDestination] = useState("");
  const [sectionId, setSectionId] = useState(1);
  const [timeInterVal, setTimeInterval] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [totalSell, setTotalSell] = useState(0);

  const [startDate, setStartDate] = useState(
    moment(new Date()).local().format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(tomorrow).local().format("YYYY-MM-DD")
  );
  const [analyticsData, setAnalyticsData] = useState([
    {
      heading: CONSTANTS.TOTAL_CUSTOMERS,
      value: "$0",
    },
    {
      heading: CONSTANTS.NEW_CUSTOMERS,
      value: "$0",
    },
    {
      heading: CONSTANTS.RETURN_CUSTOMERS,
      value: "$0",
    },
    {
      heading: CONSTANTS.TOTAL_CASH_DISCOUNTS,
      value: "$0",
    },
    {
      heading: CONSTANTS.TOTAL_MOBILE_COUPONS,
      value: "$0",
    },
    {
      heading: CONSTANTS.NEW_CUSTOMERS_SALES,
      value: "$0",
    },
    {
      heading: CONSTANTS.RETURN_CUSTOMERS_SALES,
      value: "$0",
    },
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let sDate;
      let eDate;
      eDate = moment(tomorrow).local().format("YYYY-MM-DD");

      if (timeInterVal === "0") {
        sDate = moment(new Date()).local().format("YYYY-MM-DD");
      } else if (timeInterVal === "1") {
        const fistDate = new Date();
        fistDate.setDate(fistDate.getDate() - 7);

        sDate = moment(fistDate).local().format("YYYY-MM-DD");
      } else if (timeInterVal === "2") {
        const fistDate = new Date();
        fistDate.setDate(fistDate.getDate() - 30);
        sDate = moment(fistDate).local().format("YYYY-MM-DD");
      } else {
        sDate = startDate;
        eDate = endDate;
      }
      try {
        const res = await GetDashboardData(sDate, eDate);
      } catch (error) {}
      setIsLoading(false);
    })();
  }, [timeInterVal, startDate, endDate]);

  return (
    <div className="main-content position-relative h-100 border-radius-lg">
      <PageHeader destination={destination} heading={heading} />
      {isLoading && (
        <LinearProgress color="inherit" sx={{ margin: "0px 10px" }} />
      )}
      {sectionId !== 6 && (
        <div className="container-fluid mt-4">
          <div className="top_actionbar">
            <div className="row">
              <div className="col-xl-3 col-sm-5 mb-xl-0 mb-4">
                {" "}
                <select
                  className="time_interval_option"
                  id="inputGroupSelect04"
                  defaultChecked={timeInterVal}
                  onChange={(e) => setTimeInterval(e.target.value)}
                >
                  <option value="0">DAILY</option>
                  <option value="1">WEEKLY</option>
                  <option value="2">MONTHLY</option>
                  <option value="3">CUSTOM</option>
                </select>
              </div>
              <div className="col-xl-9 col-sm-7 mb-xl-0 mb-4">
                <p className="reportowner">
                  {USER_DATA.user?.firstname + " " + USER_DATA.user?.lastname}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid ">
        {sectionId !== 6 && timeInterVal === "3" && (
          <CustomDate
            updateSDate={(e) => setStartDate(e.target.value)}
            updateLDate={(e) => setEndDate(e.target.value)}
          />
        )}
      </div>
    </div>
  );
}
