import React from "react";
import ReactDOM from "react-dom";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "./index.css";
import Layout from "./components/layout/Layout";
import theme from "./theme";
import { CONSTANTS } from "./utils/CONSTANTS";
import NavProvider from "./store/context";
import Helmet from "react-helmet";
import "./assets/css/dashboard.css";
import "./assets/css/transaction.css";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

document.title = CONSTANTS.WEBSITE_HEADER_NAME;

ReactDOM.render(
  <>
    <NavProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Layout />
        </ThemeProvider>
      </BrowserRouter>
    </NavProvider>
  </>,
  document.getElementById("root")
);
