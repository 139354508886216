import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Dashboard/home";
import AllMerchants from "../pages/Business/AllMerchants/AllMerchants";
const AllRoutes = () => {
  return (
    <Routes>
      <Route exact path="/all_merchants" element={<AllMerchants />} />
      <Route exact path="login" element={<Home />} />
      <Route path="/*" element={<Navigate to="/all_merchants" />} />
    </Routes>
  );
};

export default AllRoutes;
