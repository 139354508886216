import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAPI from "../CustomeHooks/API";
import { NavConstant } from "../store/context";
import { API_ROUTE, USER_DATA } from "../utils/CONSTANTS";
import { authStorage } from "../utils/login";
import { Tooltip } from "@mui/material";
import LogOutIcon from "../images/logout.png";

export default function PageHeader({ destination, heading, subDestination }) {
  const navContext = useContext(NavConstant);
  const navigate = useNavigate();
  const logOutHandler = () => {
    authStorage.deleteAuthDetails();
    navContext.checkIsLogin();
    navigate("/");
  };

  const merchantAPI = useAPI(API_ROUTE.getAllMerchants);

  let merchantaArr = [];

  useEffect(() => {
    if (authStorage.role !== "merchant") {
      merchantAPI.apiCall();
    }
  }, []);

  if (merchantAPI.res !== -2 && merchantAPI.res !== -1) {
    let resData = merchantAPI.res.data.data.data;
    merchantaArr = resData.map((row, index) => {
      if (index === 0) {
        navContext.setMerchantId(row.mid);
        navContext.setMerchantEmail(row.email);
        navContext.setMerchantCouponExpire(row.coupon_expires_in);
        authStorage.setMToken(row.access_token);
        authStorage.setMID(row.mid);
      }
      return {
        mid: row.mid,
        name: row.firstname + " " + row.lastname,
      };
    });
  }

  const setNewMerchantId = (id) => {
    if (merchantAPI.res !== -2 && merchantAPI.res !== -1) {
      let merchantSelected = merchantAPI.res.data.data.data.filter(
        (merchant) => merchant.mid === id
      )[0];
      navContext.setMerchantId(merchantSelected.mid);
      navContext.setMerchantEmail(merchantSelected.email);
      navContext.setMerchantCouponExpire(merchantSelected.coupon_expires_in);
      authStorage.setMID(id);
      authStorage.setMToken(merchantSelected.access_token);
    }
  };

  return (
    <nav
      className="navbar navbar-main navbar-expand-lg px-0 mx-2 shadow-none border-radius-xl"
      id="navbarBlur"
      navbar-scroll="true"
    >
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
            <li className="breadcrumb-item text-sm">
              <Link className="opacity-5 text-dark" to="/">
                Home
              </Link>
            </li>
            {destination && (
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                {destination}
              </li>
            )}
            {subDestination && (
              <li
                className="breadcrumb-item text-sm text-dark active"
                aria-current="page"
              >
                {subDestination}
              </li>
            )}
          </ol>
          <h6 className="font-weight-bolder mb-0 page_title">{heading}</h6>
        </nav>
        <div className="d-flex">
          {/* {USER_DATA.user?.role?.toLowerCase() === "admin" && (
            <div className="me-2">
              {merchantaArr.length > 0 && (
                <select className="time_interval_option">
                  {merchantaArr.map((merchant) => (
                    <option
                      value={merchant.mid}
                      onClick={() => setNewMerchantId(merchant.mid)}
                    >
                      {merchant.name} ({merchant.mid}){" "}
                    </option>
                  ))}
                </select>
              )}
            </div>
          )} */}
          <div className="nav-item  ps-3 d-flex align-items-center">
            <div className="Log-out-wrapper" onClick={logOutHandler}>
              <Tooltip title="Log Out">
                <div>
                  Log Out &nbsp;{" "}
                  <img src={LogOutIcon} title="LogOut" height=" 19px" />
                </div>
              </Tooltip>
            </div>

            <a
              className="nav-link d-xl-none text-body p-0"
              id="iconNavbarSidenav"
            >
              <div
                className="sidenav-toggler-inner"
                onClick={() =>
                  navContext.setIsNavigationOverLap(
                    navContext.isNavigationOverLap
                  )
                }
              >
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
