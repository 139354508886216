export const CONSTANTS = {
  WEBSITE_HEADER_NAME: "GivePay Super Admin",
  NAVIGATION_PENAL_MENU: [
    {
      id: 4,
      heading: "Business",
      subList: [
        {
          id: 3,
          path: "/all_merchants",
          label: "All Merchants",
          role: {
            admin: true,
          },
        },
      ],
      role: {
        admin: true,
      },
    },
  ],

  CONFIRM: "Confirm",
  AGENT_PARTNER: "Agent / Partner",
  CORPORATE: "Corporate",
  REGION_MARKET: "Region / Market",
  DISTRICT: "District",

  //   User Page
  TITLE: "Setup",
  SUB_TITLE: "Setup Web Dashboard Users",
  WELCOME_LINE:
    "Setup Dashboard Users with external website access - Non Point of Sale access",
  SETUP_USER: "Setup Agent / Partner / Users",
  GIVEPAY_USER: "GivePay Users",
  PLACE_HOLDERS: {
    EMAIL: "Email (User Name)",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    MOBILE_NUMBER: "Mobile Number. (2FA)",
  },
  USER_ROLE: ["Admin", "Support"],
  TWO_FACTOR_AUTH_NOTICE:
    "* I Opt-in to confirm access via 2 Factor Authentication and to receive marketing and promotional offers from GivePay and ( Your Merchant Name), Message and data rates may apply.",
  INPUT_DAY_PLACEHOLDER: "Enter number of Days",

  //   Dashboard
  DAY: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  INTERVAL: ["DAILY", "WEEKLY", "MONTHLY"],
  HAPPY: "Happy",
  lEVEL: {
    AGENT: "Agent Partner",
    COMPANY: "Company",
    REGION: "Region Market",
    DISTRICT: "District",
    LOCATION: "Location",
    TRANSACTION: "Transaction",
  },
  SEARCH_FIELD_PLACE_HOLDERS: "Search...",
  SUB_LINE:
    "Here’s a snapshot of how you’re doing this week at the Agent/Partner level",
  TOTAL_CUSTOMERS: "Total Customers",
  NEW_CUSTOMERS: "New Customers",
  RETURN_CUSTOMERS: "Return Customers",
  TOTAL_CASH_DISCOUNTS: "Total Cash Discounts",
  TOTAL_MOBILE_COUPONS: "Total Mobile Coupons",
  NEW_CUSTOMERS_SALES: "New Customers Sales",
  RETURN_CUSTOMERS_SALES: "Return Customers Sales",
  DashboardTITLE: "Dashboard Overview",
  MY_DASHBOARD: "MY DASHBOARD",

  //   Transaction Page
  TRANSACTIONS: "TRANSACTIONS",
  TIME_INTERVAL: "TIME INTERVAL ",
  TRANSACTIONS_TITLE: "Reporting Overview",
  TRANSACTION_DETAIL: "Transaction Detail",
  TRANSACTIONS_TABLE_COLUMN: [
    {
      id: 0,
      title: "Created",
    },
    {
      id: 2,
      title: "Trance ID",
    },
    {
      id: 3,
      title: "Customer",
    },
    {
      id: 4,
      title: "Discount Type",
    },
    {
      id: 5,
      title: "Transaction Total",
    },
    {
      id: 6,
      title: "Discount Total",
    },
    {
      id: 7,
      title: "Text Opt-In",
    },
    {
      id: 8,
      title: "Coupon Sent",
    },
    {
      id: 9,
      title: "Coupon Expiration",
    },
    {
      id: 10,
      title: "Scheduled Text Reminders",
    },
    {
      id: 11,
      title: "Resend Text Reminders",
    },
    {
      id: 12,
      title: "Reminder Date",
    },
  ],
  TRANSACTION_WARNING_FOR_MANAGER:
    "Transaction detail visible to managers/owners only",
  TRANSACTIONS_NOTE:
    "*Note: Mobile Coupons bill at 10¢ per message sent (limit 2 text reminders per customer per month)",

  API_NAME: {
    "Cash Discount": "card",
    "Cash or Card": "cash",
  },

  RESPONSE_STATUS: {
    success: true,
  },

  MID: "QZ8JPK37NFM71",

  LABEL_TITLE: "Labels",
  LABEL_SUB_TITLE: "Print custom Pricing Labels with  AveryTM  and GivePayTM",
  LABEL_SUB_TITLE_2: "Every design & print online guide",
  LABEL_LINE_1:
    "Choose a blank or pre-designed free template, then add text and images.",
  LABEL_LINE_2: " Avery Design & Print - Design. Print. Go!™",
  LABEL_LINE_3:
    " With GivePay and Avery Design & Print, creating quality pricing labels has never been easier.",
  LABEL_LINE_4:
    " Just choose a template size to print pricing, or select Start Designing to customize your print size, then print it yourself or let Avery print it for you.",
  LABEL_CTA_TEXT: "Start Designing",
  WE_PRINT_TEXT: "WePrint",

  LABELS: [
    {
      id: 0,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 1,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 2,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: 5,
      ratingData: "4.5 (1)",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 3,
      label: "Looking for our In-store Packs?",
      link: "assets/img/store.jpg",
      size: "",
      rating: "",
      ratingData: "",
      note: "Purchase online or in stores",
    },
    {
      id: 4,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 5,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: "",
      ratingData: "",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 6,
      label: "Rectangle Label",
      link: "assets/img/l1.jpg",
      size: ' 1" x 2.625"',
      rating: 5,
      ratingData: "4.5 (1)",
      note: "14 Labels per sheet Print to the Edge",
    },
    {
      id: 7,
      label: "Looking for our In-store Packs?",
      link: "assets/img/store.jpg",
      size: "",
      rating: "",
      ratingData: "",
      note: "Purchase online or in stores",
    },
  ],
  LABEL_FREE_TEMPLATE:
    "Personalize one of our proffessional templates with our large clipart & font library, or upload your own artwork.",
  LABEL_BARCODE:
    " Create unique barcodes to add to product packaging labels or add sequential numbering to raffle tickets & price tag.",
  TERMS_OF_USAGE_URL: "https://www.givepay.com/terms",
  FAQ_URL: "https://www.givepay.net/pricing/",
  ADD_BUSSINESS_HEADING: "Add Business",
  ADD_BUSSINESS_LINE:
    "Setup Dashboard Users with external website access - Non Point of Sale access",
  ALL_BUSSINESS_HEADING: "All Business",
  ALL_MERCHANTS_HEADING: "All MERCHANTS",
};

export const API_ROUTE = {
  login: { type: "post", url: "/auth/login" },
  forgot: { type: "post", url: "/auth/password/forgot" },
  updatePassword: { type: "patch", url: "/auth/password" },
  getMe: { type: "get", url: "/profile" },
  updateMe: { type: "patch", url: "/merchants" },
  getAllMerchants: { type: "get", url: "/merchants" },
};

export const NOTIFICATION_MESSAGE = {
  InValidId: { type: false, message: "Invalid ImageID & Password" },
  LOGIN_SUCCESS: { type: true, message: "Login Success" },
  MSG_SEND: { type: true, message: "Message sent!" },
  ADD_SUCCESS: { type: true, message: "Added" },
  UPDATE_PASS_SUCCESS: { type: true, message: "User Password Updated" },
  UPDATE_SUCCESS: { type: true, message: "Updated" },
  SOMETHING_WRONG: { type: false, message: "Something Went Wrong" },
};

export const COLOR = {
  THEME: {
    primary: "#1D94E6",
    secondory: "#228800",
  },
};

export const USER_DATA = {
  user: {},
};
