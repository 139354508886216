import React, { useContext, useEffect } from "react";

import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";
import { authStorage } from "../../utils/login";
import backgroundImage from "../../images/login_background.png";
import { NavConstant } from "../../store/context";
import "./login.css";

import logo from "../../images/login_logo.png";
import { UserLogin } from "../../api/allServices";
import { API_ROUTE, COLOR, NOTIFICATION_MESSAGE } from "../../utils/CONSTANTS";
import useAPI from "../../CustomeHooks/API";
import PhoneInput from "react-phone-input-2";

const getButtonText = {
  true: "Send Request",
  false: "Login",
};

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("no");
  const [passwordError, setPasswordError] = React.useState("no");
  const [countryCode, setCountryCode] = React.useState("");
  const [isForgot, setIsForgot] = React.useState(false);
  const loginAPI = useAPI(API_ROUTE.login, NOTIFICATION_MESSAGE.LOGIN_SUCCESS);
  const forgotPassword = useAPI(API_ROUTE.forgot);
  const ctx = useContext(NavConstant);
  const navigate = useNavigate();

  const signIn = () => {
    if (isForgot) {
      if (countryCode.length < 3) {
        setEmailError("please Enter Email ID");
        return;
      }
      const payload = {
        mobile: +countryCode,
      };

      forgotPassword.apiCall(payload);
    } else {
      if (email == "") {
        setEmailError("please Enter Email ID");
        return;
      }
      if (password == "") {
        password == "" && setPasswordError("please Enter password");
        return;
      }
      const payload = {
        email: email.toLowerCase(),
        password,
      };

      loginAPI.apiCall(payload);
    }
  };

  useEffect(() => {
    if (loginAPI.res != -2) {
      console.log(loginAPI.res);
      if (loginAPI.res.data) {
        if (loginAPI.res?.data?.status === "success") {
          authStorage.setAuthDetails(loginAPI.res?.data?.token);
          ctx.checkIsLogin();
          window.location.assign("/all_merchants");
        }
      }
    }
  }, [loginAPI.res]);

  useEffect(() => {
    if (forgotPassword.res != -2 || forgotPassword.res != -1) {
      setIsForgot(false);
    }
  }, [forgotPassword.res]);

  return (
    <section className="app-login">
      <img
        className="login-background"
        src={backgroundImage}
        alt="background"
      />

      <div className="login-welcome">
        <img className="login-img" src={logo} alt="logo" />
      </div>

      <div className="login-cpm">
        <form autoComplete={false} onSubmit={(e) => e.preventDefault()}>
          {!isForgot && (
            <div className="form-feild">
              <label className="form__group__lable">
                Email ID{" "}
                {emailError != "no" && (
                  <span className="login-warning">{emailError}</span>
                )}
              </label>
              <div>
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="component-simple">
                    Enter your email here
                  </InputLabel>
                  <Input
                    value={email}
                    onChange={(e) => {
                      setEmailError("no");
                      setEmail(e.target.value);
                    }}
                    type="email"
                    id="component-simple"
                    fullWidth
                  />
                </FormControl>
              </div>
            </div>
          )}
          {!isForgot && (
            <div className="form-feild">
              <label className="form__group__lable">
                Password{" "}
                {passwordError != "no" && (
                  <span className="login-warning">{passwordError}</span>
                )}
              </label>
              <div className="form__group field">
                <FormControl variant="standard" fullWidth>
                  <InputLabel htmlFor="component-simple">
                    Enter your password here
                  </InputLabel>
                  <Input
                    value={password}
                    onChange={(e) => {
                      setPasswordError("no");
                      setPassword(e.target.value);
                    }}
                    id="component-simple"
                    type="password"
                    fullWidth
                  />
                </FormControl>
              </div>
            </div>
          )}

          {!isForgot && (
            <p className="forgotPassword" onClick={() => setIsForgot(true)}>
              Forgot Password ?
            </p>
          )}

          {isForgot && (
            <div className="input-group" style={{ display: "contents" }}>
              <PhoneInput
                country={"us"}
                onChange={(phone) => setCountryCode(phone)}
                inputClass="countryCode"
                style={{ border: "1px solid #CACACA", borderRadius: "3px" }}
              />
            </div>
          )}

          {isForgot && (
            <p onClick={() => setIsForgot(true)}>
              You will recive your password on your entered mobile Number
            </p>
          )}

          <button
            onClick={signIn}
            className={`form-feild-buttom ${
              loginAPI.loading ? "disabled" : ""
            }`}
            disabled={loginAPI.loading || forgotPassword.loading}
          >
            {loginAPI.loading || forgotPassword.loading
              ? "Loading..."
              : getButtonText[isForgot]}
          </button>

          {isForgot && (
            <p
              className="forgotPassword text-center mt-3"
              onClick={() => setIsForgot(false)}
              style={{ marginBottom: "-20px" }}
            >
              &larr; Back to logIn
            </p>
          )}
        </form>
      </div>
    </section>
  );
};

export default Login;
